







































import Vue, { VueConstructor } from 'vue';
import { FormWrapperRequest } from '@/types/form';
import { CommentForm, CommentModel } from '@/types/comments';
import formSubmit from '@/mixins/formSubmit';
import ApiError from '@/components/ApiError.vue';
import Btn from '@/components/buttons/Btn.vue';
import AppModal from '@/components/AppModal.vue';

function getDefaultModel(comment?: CommentModel|null, isReply = false) {
  const model: CommentForm = {
    versionId: '',
    parentId: '',
    label: '',
  };

  if (comment) {
    if (isReply) {
      model.parentId = comment.id;
    } else {
      model.versionId = comment.versionId;
      model.parentId = comment.parentId;
      model.label = comment.label;
    }
  }

  return model;
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof formSubmit>>).extend({
  name: 'CommentForm',
  components: {
    Btn,
    AppModal,
    ApiError,
  },
  mixins: [formSubmit],
  props: {
    hideButtons: Boolean,
  },
  computed: {
    projectId(): string {
      return this.$route.params.projectId;
    },
    diagramId(): string {
      return this.$route.params.diagramId;
    },
    versionId(): string {
      const version = this.$store.getters['diagramModule/version'];
      return version.id || '';
    },
    isEditForm(): boolean {
      return !this.isReply && !!this.comment;
    },
    title(): string {
      if (this.isReply) {
        return 'Add your reply';
      }
      const action = this.isEditForm ? 'Edit' : 'Add';
      return `${action} Comment`;
    },
    updateUrl(): string {
      if (this.$isOrgRole(this.$Roles.GUEST)) {
        return `/org/${this.$route.params.orgId}/shared/diagrams/${this.diagramId}/versions/comments`;
      }
      return `/project/${this.projectId}/diagrams/${this.diagramId}/versions/comments`;
    },
    request(): FormWrapperRequest {
      const url = this.updateUrl;

      if (this.isEditForm) {
        return {
          config: {
            url,
            method: 'put',
            data: this.model,
          },
        };
      }

      return {
        config: {
          url,
          method: 'post',
          data: this.model,
        },
      };
    },
  },
  data() {
    return {
      isReply: false,
      model: getDefaultModel(),
      comment: null as null|CommentModel,
    };
  },
  methods: {
    open(comment: CommentModel|null = null, isReply = false) {
      this.comment = comment;
      this.isReply = isReply;

      this.setDefaultModel();
      (this.$refs.modal as any).open();
    },
    onClose() {
      this.resetValidation();
      this.$emit('close');
    },
    async onSubmit() {
      this.error = '';
      this.model.versionId = this.versionId;
      if (this.comment) {
        if (this.isReply) {
          this.model.parentId = this.comment.parentId || this.comment.id;
        } else {
          delete (this.model as any).parentId;
          (this.model as any).id = this.comment.id;
        }
      }

      await this.submit(this.request, this.$refs.form);
      if (this.hasError) {
        this.loading = false;
        return;
      }

      (this.$refs.modal as any).close();
      this.$emit('submit');
      this.notify(`Comment ${this.isEditForm ? 'updated' : 'posted'}`);
      this.loading = false;
    },
    textValidation(text: string) {
      if (!text) {
        return 'The Text is required';
      }
      if (text.length > 1000) {
        return 'The Text must be less than 1000 characters';
      }
      return true;
    },
    setDefaultModel() {
      this.model = getDefaultModel(this.comment, this.isReply);
    },
  },
});
