






























import Vue, { PropType } from 'vue';
import { CommentActions, CommentModel } from '@/types/comments';
import DropdownMenu from '@/components/menu/DropdownMenu.vue';
import DropdownDropdownMenuItem from '@/components/menu/DropdownMenuItem.vue';
import { UserDetails } from '@/types/profile';

export default Vue.extend({
  name: 'CommentButtons',
  components: { DropdownDropdownMenuItem, DropdownMenu },
  props: {
    comment: {
      type: Object as PropType<CommentModel>,
      required: true,
    },
  },
  computed: {
    user(): UserDetails {
      return this.$store.getters['profileModule/user'];
    },
    isActive(): boolean {
      return this.comment.status === 'active';
    },
    myComment(): boolean {
      return this.$store.getters['profileModule/user'].id === this.comment.createdBy;
    },
    isReply(): boolean {
      return this.comment.parentId !== '';
    },
    hasActions(): boolean {
      return this.canEdit
        || this.canDelete
        || this.canResolve
        || this.canAddCallout
        || this.canRemoveCallout;
    },
    canEdit(): boolean {
      if (this.$hasProjectRole(this.$Roles.PROJECT_MANAGER)) {
        return this.isActive;
      }

      return this.isActive && this.myComment;
    },
    canDelete(): boolean {
      if (this.$hasProjectRole(this.$Roles.PROJECT_MANAGER)) {
        return true;
      }

      return this.myComment;
    },
    canResolve(): boolean {
      if (this.$hasProjectRole(this.$Roles.PROJECT_MANAGER)) {
        return this.isActive && !this.isReply;
      }

      if (this.$hasProjectRole(this.$Roles.CONTRIBUTOR)) {
        return this.isActive && !this.isReply;
      }

      return this.myComment;
    },
    canAddCallout(): boolean {
      return this.canResolve && this.comment.callout < 1;
    },
    canRemoveCallout(): boolean {
      return this.canResolve && this.comment.callout > 0;
    },
  },
  data() {
    return {
      actions: CommentActions,
    };
  },
});
