


































import Vue, { VueConstructor } from 'vue';
import formSubmit from '@/mixins/formSubmit';
import { FormWrapperRequest } from '@/types/form';
import Btn from '@/components/buttons/Btn.vue';
import ApiError from '@/components/ApiError.vue';
import AppModal from '@/components/AppModal.vue';
import { diagramEvents } from '@/bridge/events/diagramEvents';
import { CommentModel } from '@/types/comments';

export default (Vue as VueConstructor<Vue & InstanceType<typeof formSubmit>>).extend({
  name: 'DeleteModal',
  components: {
    Btn,
    AppModal,
    ApiError,
  },
  mixins: [formSubmit],
  computed: {
    projectId(): string {
      return this.$route.params.projectId;
    },
    diagramId(): string {
      return this.$route.params.diagramId;
    },
    versionId(): string {
      const version = this.$store.getters['diagramModule/version'];
      return version.id || '';
    },
    deleteUrl(): string {
      if (this.$isOrgRole(this.$Roles.GUEST)) {
        return `/org/${this.$route.params.orgId}/shared/diagrams/${this.diagramId}/versions/comments/remove?versionIds=${this.versionId}`;
      }
      return `/project/${this.projectId}/diagrams/${this.diagramId}/versions/comments/remove?versionIds=${this.versionId}`;
    },
    request(): FormWrapperRequest {
      return {
        config: {
          method: 'put',
          url: this.deleteUrl,
          data: this.model,
        },
      };
    },
  },
  data() {
    return {
      model: {
        ids: [] as string[],
      },
      isReply: false,
    };
  },
  methods: {
    open(comment: CommentModel) {
      this.model.ids = [comment.id];
      this.isReply = comment.parentId !== '';
      (this.$refs.modal as any).open();
    },
    async onSubmit() {
      if (!this.model.ids.length) {
        this.error = 'Missing comment id';
        return;
      }

      await this.submit(this.request, this.$refs.form);
      if (this.hasError) {
        return;
      }

      diagramEvents.$emit(diagramEvents.DELETE_CALLOUT, this.model.ids[0]);
      this.notify('Comment deleted');
      this.$emit('deleted');
      if (this.$refs.modal) {
        (this.$refs.modal as any).close();
      }
    },
  },
});
