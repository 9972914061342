







































































import Vue, { PropType, VueConstructor } from 'vue';
import Btn from '@/components/buttons/Btn.vue';
import AppModal from '@/components/AppModal.vue';
import formSubmit from '@/mixins/formSubmit';
import ApiError from '@/components/ApiError.vue';
import FieldLabel from '@/components/fields/FieldLabel.vue';
import NoteTodoList from '@/components/notes/NoteTodoList.vue';
import { FormWrapperRequest } from '@/types/form';
import { NoteForm, NoteModel, NoteTodo } from '@/types/notes';
import EditBtn from '@/components/buttons/EditBtn.vue';

function getDefaultModel(note?: NoteForm) {
  const model: NoteForm = {
    versionId: '',
    label: '',
    description: '',
    priority: 0,
    sort: 0,
    todos: [],
  };

  if (note) {
    model.versionId = note.versionId;
    model.label = note.label;
    model.description = note.description;
    model.priority = note.priority;
    model.sort = note.sort;
    model.todos = note.todos;
  }

  return model;
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof formSubmit>>).extend({
  components: {
    EditBtn,
    Btn,
    AppModal,
    ApiError,
    FieldLabel,
    NoteTodoList,
  },
  mixins: [formSubmit],
  props: {
    hideButtons: Boolean,
    note: {
      type: Object as PropType<NoteModel>,
      required: false,
    },
  },
  computed: {
    projectId(): string {
      return this.$route.params.projectId;
    },
    diagramId(): string {
      return this.$route.params.diagramId;
    },
    versionId(): string {
      const version = this.$store.getters['diagramModule/version'];
      return version.id || '';
    },
    isEditForm(): boolean {
      return this.note && !!this.note.label;
    },
    title(): string {
      const action = this.isEditForm ? 'Edit' : 'Create';
      return `${action} Note`;
    },
    createUrl(): string {
      if (this.$isOrgRole(this.$Roles.GUEST)) {
        return `/org/${this.$route.params.orgId}/shared/diagrams/${this.diagramId}/versions/notes`;
      }
      return `/project/${this.projectId}/diagrams/${this.diagramId}/versions/notes`;
    },
    request(): FormWrapperRequest {
      const url = this.createUrl;

      if (this.isEditForm) {
        return {
          config: {
            url,
            method: 'put',
            data: this.model,
          },
        };
      }

      return {
        config: {
          url,
          method: 'post',
          data: this.model,
        },
      };
    },
  },
  data() {
    return {
      model: getDefaultModel(),
    };
  },
  methods: {
    updateTodos(todos: NoteTodo[]) {
      this.model.todos = todos;
    },
    open() {
      this.setDefaultModel();
      (this.$refs.modal as any).open();
    },
    onClose() {
      this.resetValidation();
      this.$emit('close');
    },
    async onSubmit() {
      this.error = '';
      this.model.versionId = this.versionId;
      if (this.note) {
        (this.model as any).id = this.note.id;
      }

      await this.submit(this.request, this.$refs.form);
      if (this.hasError) {
        this.loading = false;
        return;
      }

      (this.$refs.modal as any).close();
      this.$emit('submit', this.model);
      this.notify(`Note ${this.isEditForm ? 'updated' : 'created'}`);
      this.loading = false;
    },
    textValidation(text: string) {
      if (!text) {
        return 'The Text is required';
      }
      if (text.length > 1000) {
        return 'The Text must be less than 1000 characters';
      }
      return true;
    },
    setDefaultModel() {
      this.model = getDefaultModel(this.note);
    },
  },
});
