<template>
  <div class="row q-col-gutter-lg q-mb-md">
    <div>
      <draggable
        v-if="todos.length"
        v-model="updatedTodos"
        :group="dragOptions.group"
        v-bind="dragOptions"
        @start="drag = true"
        @end="onDragEnd"
      >
        <transition-group type="transition" :name="!drag ? 'flip-list' : null">
          <q-item
            dense
            class="q-px-none"
            v-for="(todo, index) in updatedTodos"
            :key="todo.id"
          >
            <q-item-section side top class="q-pt-sm">
              <q-icon name="menu" color="grey-5" size="xs"/>
            </q-item-section>
            <q-item-section>
              <FieldLabel
                required
                :value="todo.label"
                :autofocus="index === updatedTodos.length - 1"
                @input="onInput(index, $event)"
              />
            </q-item-section>
            <q-item-section side top class="q-pt-sm">
              <q-btn icon="delete" color="blue-grey-3" size="sm" flat @click="removeTodo(index)"/>
            </q-item-section>
          </q-item>
        </transition-group>
      </draggable>

      <Btn
        size="sm"
        class="q-md-md"
        color="primary"
        label="Add To-do"
        @click="addTodo"
      />
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Btn from '@/components/buttons/Btn.vue';
import FieldLabel from '@/components/fields/FieldLabel.vue';
import { getDraggableOptions } from '@/util/draggableOptions';

export default {
  name: 'NoteTodoList',
  components: {
    draggable,
    Btn,
    FieldLabel,
  },
  props: {
    todos: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      drag: false,
      dragOptions: getDraggableOptions({ group: 'noteTodos' }),
      updatedTodos: this.todos.slice(),
    };
  },
  methods: {
    onInput(index, label) {
      this.updatedTodos[index].label = label;
      this.onChange();
    },
    onDragEnd() {
      this.drag = false;
      this.updatedTodos = this.updatedTodos.map((t, i) => {
        t.sort = i;
        return t;
      });
      this.onChange();
    },
    removeTodo(index) {
      this.updatedTodos.splice(index, 1);
      this.onChange();
    },
    addTodo() {
      this.updatedTodos.push({
        id: Date.now(),
        label: '',
        isDone: false,
        sort: this.updatedTodos.length || 0,
      });
      this.onChange();
    },
    onChange() {
      this.$emit('update', this.updatedTodos);
    },
  },
};
</script>
