






















































import Vue, { PropType } from 'vue';
import AppModal from '@/components/AppModal.vue';
import Btn from '@/components/buttons/Btn.vue';
import ApiService from '@/services/api.service';
import notify from '@/util/notify';
import { DiagramVersion } from '@/types/diagram';
import { UserDetails } from '@/types/profile';
import { formatDatetime } from '@/util/date';

export default Vue.extend({
  name: 'DiagramLocked',
  components: {
    Btn,
    AppModal,
  },
  props: {
    version: {
      type: Object as PropType<DiagramVersion>,
      required: true,
    },
  },
  computed: {
    lockedMessage(): string {
      return `Diagram locked by ${this.version.lockedByName} since ${formatDatetime(this.version.lockedAt)}.`;
    },
    user(): Partial<UserDetails> {
      return this.$store.getters['profileModule/user'];
    },
    isLockingUser(): boolean {
      return this.version.lockedBy === this.user.id;
    },
  },
  methods: {
    openOrRelease() {
      if (this.isLockingUser) {
        this.releaseLock();
      } else {
        (this.$refs.modal as any).open();
      }
    },
    async releaseLock() {
      const { diagramId, projectId, versionId } = this.$route.params;
      let url = `/project/${projectId}/diagrams/${diagramId}/unlock?force=true`;
      if (versionId) {
        url = `${url}&versionId=${versionId}`;
      }

      try {
        await ApiService.put(url);
        this.$emit('unlocked');
      } catch (e) {
        notify.danger('Could not unlock the diagram');
      }
    },
  },
});
