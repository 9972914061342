























































import Vue from 'vue';
import { Diagram, Part } from 'gojs';
import { OrgModel } from '@/types/org';
import { ProjectModel } from '@/types/project';
import { DiagramModel, DiagramVersion } from '@/types/diagram';
import { AllDiagramSettings, JSONModel } from '@/bridge/types/diagramModel';
import autoSave from '@/bridge/base/AutoSave';
import { DiagramStatus } from '@/bridge/enums/diagramOptions';
import DiagramCanvas from '@/views/diagram/DiagramCanvas.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import DiagramLocked from '@/components/diagramStatus/DiagramLocked.vue';
import PageSpinner from '@/components/PageSpinner.vue';
import ExportDiagram from '@/views/diagram/toolbar/ExportDiagram.vue';
import DiagramToolbarBtn from '@/views/diagram/toolbar/DiagramToolbarBtn.vue';
import DiagramZoomSlider from '@/views/diagram/toolbar/ZoomSlider.vue';
import ReviewTabs from '@/views/bit/bitTabs/ReviewTabs.vue';
import { diagramEvents } from '@/bridge/events/diagramEvents';
import ApiService from '@/services/api.service';
import notify from '@/util/notify';

export default Vue.extend({
  name: 'ModelBit',
  components: {
    DiagramLocked,
    ReviewTabs,
    DiagramZoomSlider,
    DiagramToolbarBtn,
    DiagramCanvas,
    PageSpinner,
    Breadcrumbs,
    ExportDiagram,
  },
  computed: {
    org(): OrgModel {
      return this.$store.getters['orgModule/details'];
    },
    project(): ProjectModel {
      return this.$store.getters['projectModule/details'];
    },
    diagramDetails(): DiagramModel|null {
      return this.$store.getters['diagramModule/details'];
    },
    diagramModel(): JSONModel|null {
      return this.$store.getters['diagramModule/model'];
    },
    settings(): AllDiagramSettings|null {
      return this.$store.getters['diagramModule/settings'];
    },
    projectSettings(): AllDiagramSettings|null {
      return this.$store.getters['projectModule/canvasSettings'];
    },
    version(): DiagramVersion|null {
      return this.$store.getters['diagramModule/version'];
    },
    isPublished(): boolean {
      if (this.version && this.version.status) {
        return this.version.status === DiagramStatus.PUBLISHED;
      }
      return false;
    },
    breadcrumbs(): any[] {
      const { versionId, diagramId } = this.$route.params;
      let diagramLabel = '';

      if (this.diagramDetails) {
        diagramLabel = this.diagramDetails.label;
        if (this.diagram && this.diagram.isModified) {
          diagramLabel = `${diagramLabel}*`;
        }
      }

      return [
        {
          label: this.org.label,
          url: `/org/${this.org.id}/projects`,
        },
        {
          label: this.project.label,
          url: `/org/${this.org.id}/project/${this.project.id}/bits`,
        },
        {
          label: 'View Diagram',
          url: `/org/${this.org.id}/project/${this.project.id}/view-bit/${diagramId}/version/${versionId}`,
        },
        {
          label: `Review ${diagramLabel}`,
        },
      ];
    },
  },
  data() {
    return {
      error: '',
      loading: false,
      diagramDivId: 'reviewBit',
      diagram: null as null|Diagram,
    };
  },
  methods: {
    async saveDiagram() {
      if (this.diagram) {
        await this.$store.dispatch('diagramModule/saveDiagram', {
          diagram: this.diagram,
          params: this.$route.params,
        });
        this.diagram.isModified = false;
      }
    },
    onCanvasLoaded() {
      this.diagram = Diagram.fromDiv(this.diagramDivId);
    },
    async toggleFullScreen() {
      const el = document.querySelector('.q-page-container');

      if (el) {
        await this.$q.fullscreen.toggle(el);
      }
    },
    async redirectToView() {
      const { diagramId, versionId } = this.$route.params;
      await this.$router.replace(`/org/${this.org.id}/project/${this.project.id}/view-bit/${diagramId}/version/${versionId}`);
    },
    async onCalloutDelete(c: Part) {
      if (!this.version || !c.data) return;

      try {
        const { projectId, diagramId } = this.$route.params;
        await ApiService.put(
          `/project/${projectId}/diagrams/${diagramId}/versions/comments`,
          { callout: -1, versionId: this.version.id, id: c.data.commentId },
        );
      } catch (e) {
        notify.info('Could not remove callout');
      }

      if (this.$refs.reviewTabs) {
        (this.$refs.reviewTabs as any).loadComments();
      }

      await this.saveDiagram();
    },
  },
  async created() {
    this.$store.dispatch('diagramModule/clear');
    await this.$store.dispatch('diagramModule/loadDetails', { ...this.$route.params, force: true });
    this.$store.dispatch('diagramModule/setDiagramDivId', this.diagramDivId);

    // The user has the required role.
    // Check if the diagram can be modified.
    if (this.isPublished) {
      await this.redirectToView();
      return;
    }

    autoSave.$on(autoSave.EVENT_SAVE, this.saveDiagram);
    diagramEvents.$on(diagramEvents.CALLOUT_DELETED, this.onCalloutDelete);
  },
  async beforeDestroy() {
    autoSave.$off(autoSave.EVENT_SAVE, this.saveDiagram);
    diagramEvents.$off(diagramEvents.CALLOUT_DELETED, this.onCalloutDelete);
  },
});
