export enum CommentActions {
  EDIT = 'edit',
  DELETE = 'delete',
  RESOLVE = 'resolve',
  REPLY = 'reply',
  ADD_CALLOUT = 'addCallout',
  REMOVE_CALLOUT = 'removeCallout',
}

export type CommentStatus = 'active' | 'approved'

export type CommentForm = {
  versionId: string;
  parentId: string;
  label: string;
};

export type CommentModel = CommentForm & {
  id: string,
  status: CommentStatus;
  callout: number;
  replies: CommentModel[],
  parentId: string;
  diagramId: string;
  updatedAt: number;
  createdAt: number;
  updatedBy: string;
  createdBy: string;
};

export type AddCalloutData = {
  commentId: string;
  status: CommentStatus;
  calloutNumber: number;
  createdBy: string;
  createdAt: number;
  projectId: string;
  diagramId: string;
  versionId: string;
  isGuest: boolean; // guest users can't call the diagram save endpoint
  orgId: string; // guest users will use the org route
}

export type UpdateCalloutData = {
  commentId: string;
  status: CommentStatus;
  visible?: boolean;
}
