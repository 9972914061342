<template>
  <q-item clickable v-bind="$attrs" v-on="$listeners">
    <q-item-section side v-if="icon">
      <q-icon :color="color" :name="icon" />
    </q-item-section>
    <q-item-section>
      {{ label }}
    </q-item-section>
  </q-item>
</template>

<script>

export default {
  name: 'DropdownDropdownMenuItem',
  props: {
    icon: String,
    color: String,
    label: {
      type: String,
      required: true,
    },
  },
};
</script>
