
















































import Vue from 'vue';
import draggable from 'vuedraggable';
import ApiService from '@/services/api.service';
import NoteForm from '@/views/diagramNotes/NoteForm.vue';
import ApiError from '@/components/ApiError.vue';
import { getDraggableOptions } from '@/util/draggableOptions';
import { NoteModel } from '@/types/notes';
import ViewNote from '@/views/diagramNotes/ViewNote.vue';

export default Vue.extend({
  name: 'ListNotes',
  components: {
    ViewNote,
    draggable,
    ApiError,
    NoteForm,
  },
  computed: {
    projectId(): string {
      return this.$route.params.projectId;
    },
    diagramId(): string {
      return this.$route.params.diagramId;
    },
    versionId(): string {
      const version = this.$store.getters['diagramModule/version'];
      if (version) {
        return version.id;
      }
      return '';
    },
    searchUrl(): string {
      if (this.$isOrgRole(this.$Roles.GUEST)) {
        return `/org/${this.$route.params.orgId}/shared/diagrams/${this.diagramId}/versions/notes/info?versionIds=${this.versionId}`;
      }
      return `/project/${this.projectId}/diagrams/${this.diagramId}/versions/notes/info?versionIds=${this.versionId}`;
    },
    updateNotesOrder(): string {
      if (this.$isOrgRole(this.$Roles.GUEST)) {
        return `/org/${this.$route.params.orgId}/shared/diagrams/${this.diagramId}/versions/notes/order`;
      }
      return `/project/${this.projectId}/diagrams/${this.diagramId}/versions/notes/order`;
    },
  },
  data() {
    return {
      drag: false,
      dragOptions: getDraggableOptions({ group: 'notes' }),
      notes: [],
      error: '',
      note: {} as NoteModel,
    };
  },
  methods: {
    async loadNotes() {
      try {
        const resp = await ApiService.get(this.searchUrl);

        if (!resp.results || !resp.results.length) {
          this.notes = [];
          return;
        }
        const nts = resp.results;

        this.notes = nts.sort((a: any, b: any) => a.sort - b.sort);
      } catch (e: any) {
        this.error = e.message;
      }
    },
    async onDragEnd() {
      try {
        const payload = [] as any;
        this.notes.forEach((note: any, index) => {
          if (note.sort) {
            note.sort = index;
          }
          payload.push({
            id: note.id,
            versionId: this.versionId,
            sort: note.sort,
          });
        });

        await ApiService.put(this.updateNotesOrder, {
          versionId: this.versionId,
          notes: payload,
        });
      } catch (e: any) {
        this.error = e.message;
      }
      this.drag = false;
    },
    onChange() {
      this.$emit('update', this.notes);
    },
  },
  async created() {
    await this.loadNotes();
  },
});
