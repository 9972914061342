

























import Vue from 'vue';
import notify from '@/util/notify';
import ApiService from '@/services/api.service';
import { diagramEvents } from '@/bridge/events/diagramEvents';
import { CommentActions, CommentModel } from '@/types/comments';
import ApiError from '@/components/ApiError.vue';
import ViewComment from '@/views/diagramComments/ViewComment.vue';
import CommentForm from '@/views/diagramComments/CommentForm.vue';
import DeleteComment from '@/views/diagramComments/DeleteComment.vue';
import FieldCheckbox from '@/components/fields/FieldCheckbox.vue';

export default Vue.extend({
  name: 'ListComments',
  components: {
    FieldCheckbox,
    DeleteComment,
    CommentForm,
    ViewComment,
    ApiError,
  },
  computed: {
    commentList(): CommentModel[] {
      if (this.showResolved) {
        return this.comments;
      }
      return this.comments.filter((c) => c.status === 'active');
    },
    projectId(): string {
      return this.$route.params.projectId;
    },
    diagramId(): string {
      return this.$route.params.diagramId;
    },
    versionId(): string {
      const version = this.$store.getters['diagramModule/version'];
      return version.id || '';
    },
    listUrl(): string {
      if (this.$isOrgRole(this.$Roles.GUEST)) {
        return `/org/${this.$route.params.orgId}/shared/diagrams/${this.diagramId}/versions/comments?versionIds=${this.versionId}`;
      }
      return `/project/${this.projectId}/diagrams/${this.diagramId}/versions/comments?versionIds=${this.versionId}`;
    },
    createUrl(): string {
      if (this.$isOrgRole(this.$Roles.GUEST)) {
        return `/org/${this.$route.params.orgId}/shared/diagram/${this.diagramId}/version/comments`;
      }
      return `/project/${this.projectId}/diagram/${this.diagramId}/version/comments`;
    },
  },
  data() {
    return {
      error: '',
      skipScrollTo: false,
      showResolved: false,
      comments: [] as CommentModel[],
      creators: {} as any,
    };
  },
  methods: {
    onClick(e: { action: CommentActions, comment: CommentModel }) {
      if (e.action === CommentActions.DELETE) {
        (this.$refs.deleteForm as any).open(e.comment);
        return;
      }

      const form = this.$refs.commentForm as any;

      if (e.action === CommentActions.EDIT) {
        form.open(e.comment);
      }
      if (e.action === CommentActions.REPLY) {
        form.open(e.comment, true);
      }
    },
    scrollToElement(elId: string) {
      // Prevent scrolling when the user clicks on
      // the callout button from the view comment component
      if (this.skipScrollTo) {
        this.skipScrollTo = false;
        return;
      }

      const el = document.getElementById(elId);
      if (el) {
        el.scrollIntoView();
      }
    },
    async onSubmit() {
      await this.loadComments();
    },
    async handleInput(value: string) {
      try {
        if (!value) {
          return;
        }
        await ApiService.post(this.createUrl,
          { label: value, versionId: this.versionId });
        notify.success('Comment created successfully');
        await this.loadComments();
      } catch {
        notify.danger('Something went wrong while persisting this comment');
      }
    },
    async loadComments() {
      this.comments = [];

      try {
        const resp = await ApiService.get(this.listUrl);

        if (!resp.results || !resp.results.length) {
          return;
        }

        this.creators = resp.creators;
        this.comments = resp.results;
      } catch (e: any) {
        this.error = e.message;
      }
      diagramEvents.$emit(diagramEvents.TOGGLE_APPROVED_CALLOUTS, this.showResolved);
    },
    onShowResolved() {
      diagramEvents.$emit(diagramEvents.TOGGLE_APPROVED_CALLOUTS, this.showResolved);
    },
  },
  async created() {
    await this.loadComments();

    diagramEvents.$on(diagramEvents.CALLOUT_CREATED, this.loadComments);
  },
  beforeDestroy() {
    diagramEvents.$off(diagramEvents.CALLOUT_CREATED, this.loadComments);
  },
});
