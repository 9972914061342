




































import Vue from 'vue';
import { Diagram } from 'gojs';
import { CalloutCategory } from '@/bridge/enums/partCategories';
import { DiagramMode } from '@/bridge/enums/diagramOptions';
import { isShape } from '@/bridge/settings/shapeSettings';
import { DiagramModel } from '@/types/diagram';
import { CanvasSelection, SelectionType } from '@/bridge/types/canvasSelection';
import { diagramEvents } from '@/bridge/events/diagramEvents';
import PartAsset from '@/views/bit/bitTabs/PartAsset.vue';
import ListComments from '@/views/diagramComments/ListComments.vue';
import DiagramLegend from '@/views/diagram/DiagramLegend.vue';
import ListNotes from '@/views/diagramNotes/ListNotes.vue';

enum Tabs {
  Notes = 'notes',
  Legend = 'diagramLegend',
  Comments = 'commentsInfo',
}

export default Vue.extend({
  name: 'ReviewTabs',
  components: {
    ListNotes,
    DiagramLegend,
    PartAsset,
    ListComments,
  },
  computed: {
    diagramDivId(): string {
      return this.$store.state.diagramModule.diagramDivId;
    },
    diagram(): Diagram|null {
      return Diagram.fromDiv(this.diagramDivId);
    },
    diagramDetails(): DiagramModel|null {
      return this.$store.state.diagramModule.details;
    },
    showPartAsset(): boolean {
      if (this.isShape || this.isCallout) return false;

      const [first] = this.selected.selection;
      if (!first) return false;

      return this.selected.selection.every((p) => {
        if (!p.data || !first.data) return false;

        const sameCategory = p.category === first.category;
        const sameLayer = p.data.group === first.data.group;
        const sameAsset = p.data.assetId === first.data.assetId;
        return sameCategory && sameLayer && sameAsset;
      });
    },
    isShape(): boolean {
      return this.selected.selection.every((p) => isShape(p.category));
    },
    isCallout(): boolean {
      if (this.selected.selection.length !== 1) return false;
      return this.selected.selection[0].category === CalloutCategory.DEFAULT;
    },
  },
  data() {
    return {
      tab: Tabs.Comments,
      reviewMode: DiagramMode.REVIEW,
      tabs: Tabs,
      selected: {
        selection: [],
        type: SelectionType.NONE,
      } as CanvasSelection,
      isMultipleSelection: false,
    };
  },
  methods: {
    async selectionChanged(e: CanvasSelection) {
      this.selected = {
        selection: [],
        type: SelectionType.NONE,
      };

      if (e.selection && e.selection.length) {
        this.selected.selection = e.selection;
        this.selected.type = e.type || SelectionType.NONE;
        this.isMultipleSelection = e.selection.length > 1;
      }

      if (this.isCallout && this.tab !== Tabs.Comments) {
        this.tab = Tabs.Comments;
      }

      if (this.isCallout) {
        const [callout] = e.selection;

        if (callout && callout.data) {
          setTimeout(() => {
            if (this.$refs.listComments) {
              (this.$refs.listComments as any).scrollToElement(e.selection[0].data.commentId);
            }
          }, 100);
        }
      }
    },
    loadComments() {
      if (this.$refs.listComments) {
        (this.$refs.listComments as any).loadComments();
      }
    },
    onKeyup(e: KeyboardEvent) {
      if (e.key === 'Escape' && this.diagram && (this.diagram as any)._addCallout) {
        (this.diagram as any)._addCallout = null;
        document.body.classList.remove('cursor-add-callout');
      }
    },
  },
  async created() {
    diagramEvents.$on(diagramEvents.SELECTION_CHANGED, this.selectionChanged);
    document.addEventListener('keyup', this.onKeyup);
  },
  beforeDestroy() {
    diagramEvents.$off(diagramEvents.SELECTION_CHANGED, this.selectionChanged);
    document.removeEventListener('keyup', this.onKeyup);
  },
});
