<template>
  <q-btn
    flat
    dense
    :icon="icon"
    :color="color"
    :size="btnSize"
    v-on="$listeners"
  >
    <q-menu auto-close>
      <q-list :style="`min-width: ${minWidth}px`">
        <slot></slot>
      </q-list>
    </q-menu>
  </q-btn>
</template>

<script>
export default {
  name: 'DropdownMenu',
  props: {
    icon: {
      type: String,
      default: 'more_vert',
    },
    color: {
      type: String,
      default: 'grey-8',
    },
    minWidth: {
      type: Number,
      default: 120,
    },
    btnSize: {
      type: String,
      default: 'md',
    },
  },
};
</script>
