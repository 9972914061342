













































































import Vue, { PropType } from 'vue';
import ApiError from '@/components/ApiError.vue';
import ApiService from '@/services/api.service';
import PageSpinner from '@/components/PageSpinner.vue';
import { NoteModel } from '@/types/notes';
import NoteForm from '@/views/diagramNotes/NoteForm.vue';
import DeleteModal from '@/components/DeleteModal.vue';

export default Vue.extend({
  name: 'ViewNote',
  components: {
    DeleteModal,
    PageSpinner,
    ApiError,
    NoteForm,
  },
  props: {
    hideHeader: Boolean,
    note: {
      type: Object as PropType<NoteModel>,
      required: true,
    },
  },
  computed: {
    hasTodos(): boolean {
      return this.note.todos && !!this.note.todos.length;
    },
    diagramId(): string {
      return this.$route.params.diagramId;
    },
    projectId(): string {
      return this.$route.params.projectId;
    },
    versionId(): string {
      const version = this.$store.getters['diagramModule/version'];
      return version.id || '';
    },
    url(): string {
      if (this.$isOrgRole(this.$Roles.GUEST)) {
        return `/org/${this.$route.params.orgId}/shared/diagrams/${this.diagramId}/versions/notes`;
      }
      return `/project/${this.projectId}/diagrams/${this.diagramId}/versions/notes`;
    },
    deleteUrl(): string {
      if (this.$isOrgRole(this.$Roles.GUEST)) {
        return `/org/${this.$route.params.orgId}/shared/diagrams/${this.diagramId}/versions/notes/remove?versionId=${this.versionId}`;
      }
      return `/project/${this.projectId}/diagrams/${this.diagramId}/versions/notes/remove?versionId=${this.versionId}`;
    },
    progress(): number {
      const doneCount = this.note.todos.filter((t) => t.isDone).length;
      return (doneCount / this.note.todos.length);
    },
  },
  data() {
    return {
      model: {} as NoteModel,
      error: '',
      loading: false,
    };
  },
  methods: {
    async updateTodos(note: NoteModel) {
      try {
        await ApiService.put(this.url, {
          id: note.id,
          versionId: note.versionId,
          todos: note.todos,
        });
      } catch (e: any) {
        this.error = e.message;
      }
    },
  },
});
