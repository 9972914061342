import { render, staticRenderFns } from "./ListNotes.vue?vue&type=template&id=60d4305a&"
import script from "./ListNotes.vue?vue&type=script&lang=ts&"
export * from "./ListNotes.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QItemSection,QSeparator,QExpansionItem,QIcon,QItemLabel});
